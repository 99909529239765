"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileNavigationAccount = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const MobileNavigationLoginLevel_1 = require("./MobileNavigationLoginLevel");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const MobileNavigationItemContent_1 = require("./MobileNavigationItemContent");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SessionActions_creators_1 = require("../../../redux/session/actions/SessionActions.creators");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const MobileNavigationAccount = (_a) => {
    var { parentLevelId, backBtnLabel, login, registration, accountMenu } = _a, menuItemProps = tslib_1.__rest(_a, ["parentLevelId", "backBtnLabel", "login", "registration", "accountMenu"]);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.navigation]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const mainWebshop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const levelId = `${parentLevelId}/Account`;
    const [isLogoutInProcess, setIsLogoutInProcess] = (0, react_1.useState)(false);
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const firstName = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetFirstName)());
    const isLoggedInView = isLoggedIn || isLogoutInProcess;
    const dispatch = (0, react_redux_1.useDispatch)();
    const logout = () => {
        setIsLogoutInProcess(true);
        dispatch(SessionActions_creators_1.default.createLogoutAction());
    };
    const getLevel = () => ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Level, { id: levelId, backBtnLabel: backBtnLabel, dataTestId: "mobile-navigation-account-level", children: ({ isActive }) => isLoggedInView ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { dataTestId: "mobile-navigation-account-title", children: (0, jsx_runtime_1.jsx)(MobileNavigationItemContent_1.MobileNavigationItemContent, { emphasized: true, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.account.popover`) }) }), accountMenu.items.map((item, index) => ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { href: item.link, dataTestId: `mobile-navigation-item-${index}`, children: (0, jsx_runtime_1.jsx)(MobileNavigationItemContent_1.MobileNavigationItemContent, { children: item.label }) }, item.link))), (0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { onClick: logout, dataTestId: "mobile-navigation-item-logout", children: (0, jsx_runtime_1.jsx)(MobileNavigationItemContent_1.MobileNavigationItemContent, { children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.logout`) }) })] })) : ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, { dataTestId: "mobile-navigation-item-registration", children: (0, jsx_runtime_1.jsx)(MobileNavigationLoginLevel_1.MobileNavigationLoginLevel, { isTabActive: isActive, actionUri: login.actionUri, registrationLink: registration.link }) })) }));
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Item, Object.assign({}, menuItemProps, { levelId: levelId, subLevel: getLevel(), verticalInnerSpacing: false, horizontalInnerSpacing: false, children: ({ openSubLevel }) => isLoggedInView ? ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { spacingClasses: "a-p-5", status: "default", title: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.loggedin.title`, { firstName }), text: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.loggedin.message`), ctaPrimary: {
                text: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.loggedin.account`),
                onClick: openSubLevel,
            }, ctaSecondary: {
                text: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.loggedin.logout`),
                onClick: logout,
            } })) : ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { spacingClasses: "a-p-5", status: "default", title: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.guest.title`), text: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.guest.message`), ctaPrimary: {
                text: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.guest.login`),
                onClick: openSubLevel,
            }, ctaSecondary: {
                text: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:navigation.account.${mainWebshop}.guest.register`),
                onClick: () => {
                    window.location.href = registration.link;
                },
            } })) })));
};
exports.MobileNavigationAccount = MobileNavigationAccount;
