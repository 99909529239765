"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PickupPointUtil {
    static getTranslation(sanitizedLocationType, locationType, t) {
        return sanitizedLocationType && locationType ? t(`${this.PICK_UP_PONT_TRANSLATION}${sanitizedLocationType}`) : '';
    }
    static isAlwaysOpen(locationType) {
        return this.POST_POINT_24.includes(locationType);
    }
}
PickupPointUtil.PICK_UP_PONT_TRANSLATION = 'buy:buy.pickup.point.';
PickupPointUtil.POST_POINT_24 = ['bpack247', 'bpack 24/7'];
exports.default = PickupPointUtil;
