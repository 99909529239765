"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const BuyLoginContainer_1 = require("./buy/BuyLoginContainer");
const BuyFastCheckoutContainer_1 = require("./buy/BuyFastCheckoutContainer");
const BuyDeliveryContainer_1 = require("./buy/buy-delivery-container/BuyDeliveryContainer");
const BuyPaymentContainer_1 = require("./buy/BuyPaymentContainer");
const BuyRegistrationContainer_1 = require("./buy/BuyRegistrationContainer");
const BuyRouteContainer_1 = require("./buy/BuyRouteContainer");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const SessionActions_creators_1 = require("../redux/session/actions/SessionActions.creators");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const RouterSelector_1 = require("../redux/router/selectors/RouterSelector");
const ScrollUtil_1 = require("../util/ScrollUtil");
const GeneralUtil_1 = require("../util/GeneralUtil");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const LockedBasketNotificationMessage_1 = require("../components/notification-message/LockedBasketNotificationMessage");
const useABTest_1 = require("../hooks/useABTest");
const BuyContainer = ({ actionUri, singleDeliveryMessage, logo, creditCardSavingLegalMessage, payPalInfoMessage, voucherCodeCollapsed, eVoucherTitle, collectInStorePromiseMsg, enableRegistrationForm, registrationForm: registrationFormProps, enableLoginForm, loginForm: loginFormProps, enableNewLoginFlows, loginFlowsForm, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const rootWithLanguage = (0, ConfigurationUtil_1.getRootWithLanguageSuffix)(aemConfiguration);
    const isFirstRender = (0, react_1.useRef)(true);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const pathname = (0, react_redux_1.useSelector)((0, RouterSelector_1.makeGetPathname)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const checkoutWithDetails = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.CHECKOUT_WITH_DETAILS);
    const isNewRegistrationStepEnabled = enableNewLoginFlows &&
        loginFlowsForm.flowType === 'with-personal-details' &&
        (!loginFlowsForm.enableAB || (loginFlowsForm.enableAB && checkoutWithDetails));
    (0, react_1.useEffect)(() => {
        dispatch(SessionActions_creators_1.default.createInitAction());
    }, []);
    (0, react_1.useEffect)(() => {
        sessionState.authorities && !shouldRender && setShouldRender(true);
    }, [sessionState, shouldRender]);
    (0, react_1.useEffect)(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const scroll = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, GeneralUtil_1.mimicDelay)(GeneralConstants_1.DELAY.DELAY_0);
            ScrollUtil_1.ScrollUtil.scrollToTop();
        });
        scroll();
    }, [pathname]);
    (0, react_1.useEffect)(() => {
        window.addEventListener('beforeunload', () => ScrollUtil_1.ScrollUtil.scrollToTop());
        return () => window.removeEventListener('beforeunload', () => ScrollUtil_1.ScrollUtil.scrollToTop());
    }, []);
    if (!shouldRender)
        return null;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [`${root}buy.html`, `${rootWithLanguage}/buy.html`], render: props => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(LockedBasketNotificationMessage_1.LockedBasketNotificationMessage, {}), (0, jsx_runtime_1.jsx)(BuyLoginContainer_1.default, Object.assign({ actionUri: actionUri, enableLoginForm: enableLoginForm, loginFormProps: loginFormProps, enableNewLoginFlows: enableNewLoginFlows, loginFlowsFormProps: loginFlowsForm }, props))] })) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                    `${root}buy.html/${common_types_1.RoutePathname.FAST_CHECKOUT}`,
                    `${rootWithLanguage}/buy.html/${common_types_1.RoutePathname.FAST_CHECKOUT}`,
                ], render: props => ((0, jsx_runtime_1.jsx)(BuyRouteContainer_1.default, { step: common_types_1.RoutePathname.FAST_CHECKOUT, component: BuyFastCheckoutContainer_1.default, componentProps: { logo }, routeProps: props })) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [`${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`, `${rootWithLanguage}/buy.html/${common_types_1.RoutePathname.DELIVERY}`], render: props => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(LockedBasketNotificationMessage_1.LockedBasketNotificationMessage, {}), (0, jsx_runtime_1.jsx)(BuyRouteContainer_1.default, { step: common_types_1.RoutePathname.DELIVERY, component: BuyDeliveryContainer_1.default, componentProps: {
                                singleDeliveryMessage,
                                eVoucherTitle,
                                collectInStorePromiseMsg,
                            }, routeProps: props })] })) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [`${root}buy.html/${common_types_1.RoutePathname.PAYMENT}`, `${rootWithLanguage}/buy.html/${common_types_1.RoutePathname.PAYMENT}`], render: props => ((0, jsx_runtime_1.jsx)(BuyRouteContainer_1.default, { step: common_types_1.RoutePathname.PAYMENT, component: BuyPaymentContainer_1.default, componentProps: { logo, voucherCodeCollapsed, creditCardSavingLegalMessage, payPalInfoMessage }, routeProps: props })) }), (enableRegistrationForm || enableNewLoginFlows) && ((0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                    `${root}buy.html/${common_types_1.RoutePathname.REGISTRATION}`,
                    `${rootWithLanguage}/buy.html/${common_types_1.RoutePathname.REGISTRATION}`,
                ], render: props => ((0, jsx_runtime_1.jsx)(BuyRegistrationContainer_1.BuyRegistrationContainer, Object.assign({ registrationFormProps: registrationFormProps, enableNewLoginFlows: isNewRegistrationStepEnabled }, props))) }))] }));
};
exports.default = BuyContainer;
