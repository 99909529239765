"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
class LocationUtil {
    static getLocationFromBrowser() {
        return new Promise((resolve, reject) => {
            window.navigator.geolocation.getCurrentPosition(position => {
                resolve({
                    lat: position.coords.latitude.toString(),
                    lng: position.coords.longitude.toString(),
                });
            }, error => {
                reject(error);
            });
        });
    }
}
_a = LocationUtil;
LocationUtil.calculateDistanceOSM = (p1, p2) => {
    const R = 6378137;
    const dLat = _a.rad(p2.latitude - p1.latitude);
    const dLong = _a.rad(p2.longitude - p1.longitude);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(_a.rad(p1.latitude)) * Math.cos(_a.rad(p2.latitude)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
};
LocationUtil.rad = (x) => (x * Math.PI) / 180;
exports.default = LocationUtil;
