"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormField = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_hook_form_1 = require("react-hook-form");
const FormField = ({ name, required, validate, renderError, children, }) => {
    const { control } = (0, react_hook_form_1.useFormContext)();
    const getErrorMessage = (message) => (renderError ? renderError(message) : message);
    if (typeof children !== 'function')
        return null;
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { control: control, name: name, rules: { required, validate }, render: ({ field, fieldState }) => {
            var _a;
            return children({
                fieldProps: field,
                state: fieldState,
                error: ((_a = fieldState === null || fieldState === void 0 ? void 0 : fieldState.error) === null || _a === void 0 ? void 0 : _a.message) ? getErrorMessage(fieldState.error.message) : undefined,
            });
        } }));
};
exports.FormField = FormField;
