"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const GeneralConstants_1 = require("../constants/GeneralConstants");
class StoreUtil {
    static getStoreMainWebShop(storeFasciaId, storeFasciaCode, mainWebShop) {
        let storeMainWebShop = '';
        if (storeFasciaId && storeFasciaCode) {
            storeMainWebShop =
                GeneralConstants_1.FASCIA_AND_MAIN_WEB_SHOP[`${storeFasciaId}_${storeFasciaCode}`];
        }
        if (!storeMainWebShop) {
            storeMainWebShop = mainWebShop;
        }
        return storeMainWebShop;
    }
    static getStoreName(store, mainWebShop, t) {
        const storeMainWebShop = this.getStoreMainWebShop(store.fasciaId, store.fasciaCode, mainWebShop);
        const fasciaPrefix = t(`general:mainwebshop.name.${storeMainWebShop}`);
        let storeName;
        if ('description' in store) {
            storeName = store.description;
        }
        else {
            storeName = store.storeName;
        }
        return storeName.startsWith(fasciaPrefix) ? storeName : `${fasciaPrefix} ${storeName}`;
    }
    static isSearchByPostCode(location) {
        return /\d/.test(location);
    }
    static formatPostCode(location, countryCode) {
        switch (countryCode) {
            case 'nl':
                return location.replace(/\s+/g, '');
            case 'gb':
                return location.replace(/\s+/g, '').replace(/^(.*)(\d)/, '$1 $2');
            default:
                return location;
        }
    }
    static isMultipleFasciasInStores(stores) {
        var _a, _b;
        const loopLength = stores.length;
        if (loopLength > 1) {
            const firstStoreFasciaId = (_a = stores[0]) === null || _a === void 0 ? void 0 : _a.fasciaId;
            for (let i = 1; i < loopLength; i++) {
                if (((_b = stores[i]) === null || _b === void 0 ? void 0 : _b.fasciaId) !== firstStoreFasciaId) {
                    return true;
                }
            }
        }
        return false;
    }
    static getStoreOwnFasciaTranslation(market, mainWebshop, t) {
        return market === 'uk' || mainWebshop === common_types_1.MainWebShop.JUTTU
            ? t(`store:store.own.fascia.first.${mainWebshop}`)
            : `${t(`general:mainwebshop.name.${mainWebshop}`)} ${t('store:store.own.fascia.first')}`;
    }
    static isAllStoresWithSameMainWebShop(mainWebShop, stores) {
        return stores.every(store => GeneralConstants_1.FASCIA_AND_MAIN_WEB_SHOP[`${store.fasciaId}_${store.fasciaCode}`] ===
            mainWebShop);
    }
    static sortStoresBy(stores, sortBy, selectedStoreId) {
        const sortedStores = stores.sort((storeA, storeB) => {
            if (sortBy === 'distance') {
                return storeA.distance - storeB.distance;
            }
            else if (sortBy === 'storeName') {
                return storeA.storeName.localeCompare(storeB.storeName);
            }
            return -1;
        });
        return selectedStoreId ? StoreUtil.moveItemToFront(sortedStores, selectedStoreId) : sortedStores;
    }
    static moveItemToFront(stores, storeId) {
        const index = stores.findIndex(item => item.storeId === storeId);
        if (index > -1) {
            const [item] = stores.splice(index, 1);
            stores.unshift(item);
        }
        return stores;
    }
}
exports.default = StoreUtil;
