"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFinderForm = exports.initialValuesStoreFinder = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
exports.initialValuesStoreFinder = {
    location: '',
};
const StoreFinderForm = ({ initialValues, onSubmit, handleResetValues, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { onSubmit: onSubmit, initialValues: initialValues, name: "store-finder-form-v2", validateOnBlur: false, autoComplete: cotopaxi_1.AutoComplete.OFF, wrapped: false, enableReinitialize: true, children: ({ errors, touched, values, setValues }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: "store-finder-location", name: "location", placeholder: t('storeFinder.popup.placeholder'), state: errors.location && touched.location ? cotopaxi_1.InputState.ERROR : undefined, adornmentEnd: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [values.location && !errors.location && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, onClick: () => {
                            setValues(exports.initialValuesStoreFinder);
                            handleResetValues();
                        }, variant: cotopaxi_1.ButtonVariant.TRANSPARENT_SUBTLE, icon: { name: cotopaxi_1.IconName.CLOSE, size: cotopaxi_1.IconSize.MEDIUM } })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { type: cotopaxi_1.ButtonType.SUBMIT, variant: values.location && !errors.location ? cotopaxi_1.ButtonVariant.BASE : cotopaxi_1.ButtonVariant.TRANSPARENT, icon: {
                            name: cotopaxi_1.IconName.MAGNIFY,
                            size: cotopaxi_1.IconSize.MEDIUM,
                            color: !values.location || errors.location ? cotopaxi_1.IconColor.SUBTLE : undefined,
                        } })] }), Component: cotopaxi_1.Input })) }));
};
exports.StoreFinderForm = StoreFinderForm;
