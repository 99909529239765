"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginFlows = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../../../util/ImageUtil");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const EmailRecognitionStep_1 = require("./steps/EmailRecognitionStep");
const LoginStep_1 = require("./steps/LoginStep");
var Steps;
(function (Steps) {
    Steps[Steps["EMAIL_RECOGNITION"] = 0] = "EMAIL_RECOGNITION";
    Steps[Steps["LOGIN"] = 1] = "LOGIN";
})(Steps || (Steps = {}));
const LoginFlows = ({ image, flowType, redirectToRegistration }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    const [step, setStep] = (0, react_1.useState)(Steps.EMAIL_RECOGNITION);
    const [email, setEmail] = (0, react_1.useState)('');
    const handleChangeStep = (email, isKnownEmail) => {
        if (isKnownEmail) {
            setStep(Steps.LOGIN);
        }
        else {
            redirectToRegistration(email);
        }
        setEmail(email);
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { bleed: { marginInline: 1.5 } }, desktop: { bleed: { marginInline: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Grid, { columns: { mobile: 1, tablet: 2 }, gap: "none", flow: "column", scalable: { min: '0', max: '1fr', repeatPattern: 'auto-fit' }, children: [image && ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: image.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix), isPicture: true }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: 1, mobile: { spacing: { paddingTop: 5, paddingBottom: 5 } }, tablet: { spacing: { paddingLeft: 6, paddingRight: 6 } }, desktop: { spacing: { paddingLeft: 12, paddingRight: 12 } }, children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [step === Steps.EMAIL_RECOGNITION && ((0, jsx_runtime_1.jsx)(EmailRecognitionStep_1.EmailRecognitionStep, { onChangeStep: handleChangeStep, initialEmail: email, flowType: flowType })), step === Steps.LOGIN && (0, jsx_runtime_1.jsx)(LoginStep_1.LoginStep, { initialEmail: email })] }) }) })] }) }));
};
exports.LoginFlows = LoginFlows;
