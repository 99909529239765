"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const SessionActions_creators_1 = require("../../redux/session/actions/SessionActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const RouterSelector_1 = require("../../redux/router/selectors/RouterSelector");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const GeneralUtil_1 = require("../../util/GeneralUtil");
const LockedBasketNotificationMessage_1 = require("../../components/notification-message/LockedBasketNotificationMessage");
const CheckoutLoginContainer_1 = require("./CheckoutLoginContainer");
const CheckoutContext_1 = require("../../context/CheckoutContext");
const CheckoutContainer = props => {
    const { rootWithLanguageSuffix, root } = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isFirstRender = (0, react_1.useRef)(true);
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const pathname = (0, react_redux_1.useSelector)((0, RouterSelector_1.makeGetPathname)());
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        dispatch(SessionActions_creators_1.default.createInitAction());
    }, []);
    (0, react_1.useEffect)(() => {
        sessionState.authorities && !shouldRender && setShouldRender(true);
    }, [sessionState, shouldRender]);
    (0, react_1.useEffect)(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const scroll = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, GeneralUtil_1.mimicDelay)(GeneralConstants_1.DELAY.DELAY_0);
            ScrollUtil_1.ScrollUtil.scrollToTop();
        });
        scroll();
    }, [pathname]);
    (0, react_1.useEffect)(() => {
        window.addEventListener('beforeunload', () => ScrollUtil_1.ScrollUtil.scrollToTop());
        return () => window.removeEventListener('beforeunload', () => ScrollUtil_1.ScrollUtil.scrollToTop());
    }, []);
    if (!shouldRender)
        return null;
    return ((0, jsx_runtime_1.jsxs)(CheckoutContext_1.CheckoutProvider, { config: props, children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [`${root}buy.html`, `${rootWithLanguageSuffix}/buy.html`], render: () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(LockedBasketNotificationMessage_1.LockedBasketNotificationMessage, {}), (0, jsx_runtime_1.jsx)(CheckoutLoginContainer_1.CheckoutLoginContainer, {})] })) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                    `${root}buy.html/${common_types_1.RoutePathname.FAST_CHECKOUT}`,
                    `${rootWithLanguageSuffix}/buy.html/${common_types_1.RoutePathname.FAST_CHECKOUT}`,
                ], render: () => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Fast Checkout" }) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                    `${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`,
                    `${rootWithLanguageSuffix}/buy.html/${common_types_1.RoutePathname.DELIVERY}`,
                ], render: () => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Delivery" }) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                    `${root}buy.html/${common_types_1.RoutePathname.PAYMENT}`,
                    `${rootWithLanguageSuffix}/buy.html/${common_types_1.RoutePathname.PAYMENT}`,
                ], render: () => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Payment" }) }), (0, jsx_runtime_1.jsx)(react_router_1.Route, { exact: true, path: [
                    `${root}buy.html/${common_types_1.RoutePathname.REGISTRATION}`,
                    `${rootWithLanguageSuffix}/buy.html/${common_types_1.RoutePathname.REGISTRATION}`,
                ], render: () => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Registration" }) })] }));
};
exports.default = CheckoutContainer;
