"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const ListerActions_creators_1 = require("../../redux/lister/actions/ListerActions.creators");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const StoreFinderPopup_1 = require("../../components/product-detail/store-finder-popup/StoreFinderPopup");
const CustomerActions_creators_1 = require("../../redux/customer/actions/CustomerActions.creators");
const CustomerSelector_1 = require("../../redux/customer/selectors/CustomerSelector");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const InStoreFilterPopupContainer = () => {
    const popupState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketCCPopupState)());
    const urlInfo = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlQueryInformationState)());
    const inStoreFilterState = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetInStoreFilterState)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleClosePopup = () => dispatch(BasketActions_creators_1.basketActions.createCCPopupCloseAction());
    const updateInStoreFilter = (store) => dispatch(ListerActions_creators_1.listerActions.updateInStoreFilter(store));
    const updateStoreId = (storeId) => dispatch(UrlActions_creators_1.urlActions.updateStoreId(storeId));
    const storeFilterApplied = () => dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterApplied());
    (0, react_1.useEffect)(() => {
        if (!profile && isLoggedIn) {
            dispatch(CustomerActions_creators_1.default.getProfile());
        }
    }, [profile, isLoggedIn]);
    (0, react_1.useEffect)(() => {
        const inStoreFilter = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY);
        if (!activeStoreId &&
            inStoreFilter &&
            (!inStoreFilterState || (inStoreFilterState === null || inStoreFilterState === void 0 ? void 0 : inStoreFilterState.storeId) !== inStoreFilter.storeId)) {
            updateInStoreFilter(inStoreFilter);
        }
    }, [inStoreFilterState]);
    const handleAddToFilter = (storeId, storeName) => {
        updateStoreId(storeId);
        updateInStoreFilter({ storeId, storeName });
        storeFilterApplied();
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, { storeId, storeName });
    };
    return ((0, jsx_runtime_1.jsx)(StoreFinderPopup_1.default, { isOpen: !popupState.collapsed, urlInfo: urlInfo, addToFilter: handleAddToFilter, handleClosePopup: handleClosePopup, type: common_types_1.StoreFinderType.IN_STORE_FILTER }));
};
exports.default = InStoreFilterPopupContainer;
