"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const StoreUtil_1 = require("../../util/StoreUtil");
class StoreAPI {
    setClickAndCollectStoreId(servicesEndpoint, defaultRequestParams, storeId) {
        const updateClickAndCollectStoreUrl = `${servicesEndpoint}api/buy/delivery/clickandcollect`;
        common_utils_1.CookieUtil.set(common_types_1.Cookie.CLICK_AND_COLLECT_STORE, storeId.toString(), { secure: true });
        const axiosConfig = {
            method: 'POST',
            url: updateClickAndCollectStoreUrl,
            params: Object.assign(Object.assign({}, defaultRequestParams), { storeId: storeId }),
            withCredentials: true,
        };
        return axios_1.default.request(axiosConfig);
    }
    getClickAndCollectStores(servicesEndpoint, defaultRequestParams, location, inStore, allowMultiFascia, sortBy, selectedStoreId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a;
            const cookie = common_utils_1.CookieUtil.get(common_types_1.Cookie.CLICK_AND_COLLECT_STORE);
            const axiosConfig = {
                method: 'GET',
                url: `${servicesEndpoint}api/store/clickandcollect/?market=${defaultRequestParams.market}&mainWebShopName=${defaultRequestParams.mainWebshop}&webShopId=${defaultRequestParams.shopId}`,
                params: {
                    allowMultiFasciaClickAndCollect: allowMultiFascia,
                    language: defaultRequestParams.language,
                    latitude: location.lat,
                    longitude: location.lng,
                    inStore,
                },
            };
            if (cookie) {
                axiosConfig.params.storeId = cookie;
            }
            const request = yield axios_1.default.request(axiosConfig);
            if (((_a = request === null || request === void 0 ? void 0 : request.data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const allStores = request.data.filter((store) => store.longitude && store.latitude);
                return StoreUtil_1.default.sortStoresBy(allStores, sortBy, selectedStoreId);
            }
            return request.data;
        });
    }
    getClickAndCollectStoresOpeningsHours(servicesEndpoint_1, defaultRequestParams_1, businessUnitId_1, fictiveShopId_1, productData_1, location_1, onlyInStock_1, onlyInSpecialtyStore_1, sortBy_1) {
        return tslib_1.__awaiter(this, arguments, void 0, function* (servicesEndpoint, defaultRequestParams, businessUnitId, fictiveShopId, productData, location, onlyInStock, onlyInSpecialtyStore, sortBy, isShopLanding = false, selectedStoreId) {
            var _a;
            const axiosConfig = {
                method: 'POST',
                url: `${servicesEndpoint}api/basket/store/openingshours`,
                data: {
                    market: defaultRequestParams.market,
                    businessUnitId,
                    mainWebShop: defaultRequestParams.mainWebshop,
                    fictiveShopId,
                    language: defaultRequestParams.language,
                    latitude: location === null || location === void 0 ? void 0 : location.lat,
                    longitude: location === null || location === void 0 ? void 0 : location.lng,
                    onlyInStock,
                    onlyInSpecialtyStore,
                    products: productData,
                },
            };
            const request = yield axios_1.default.request(axiosConfig);
            if (((_a = request === null || request === void 0 ? void 0 : request.data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const allStores = request.data.filter((store) => store.longitude && store.latitude);
                const firstStore = allStores[0];
                let stores = allStores;
                let sortedStores = stores;
                if (isShopLanding) {
                    stores = allStores.slice(1);
                }
                stores = StoreUtil_1.default.sortStoresBy(stores, sortBy, selectedStoreId);
                if (isShopLanding) {
                    sortedStores = [firstStore, ...stores];
                }
                return sortedStores;
            }
            return request.data;
        });
    }
    getShopLandingStoreInfo(servicesApi, defaultRequestParams, shopNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/services/public/store/shoplanding/${defaultRequestParams.mainWebshop}/${shopNumber}/${defaultRequestParams.language}`,
            };
            try {
                const request = yield axios_1.default.request(axiosConfig);
                return request.data;
            }
            catch (_a) {
                return null;
            }
        });
    }
    getShoplandingInfo(servicesApi, market, shopNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/shoplanding/storenumberads/${market}/${shopNumber}`,
            };
            try {
                const request = yield axios_1.default.request(axiosConfig);
                return request.data;
            }
            catch (_a) {
                return null;
            }
        });
    }
    getStoreInfoByStoreId(servicesApi, defaultRequestParams, storeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/store/info/${defaultRequestParams.mainWebshop}/${storeId}?language=${defaultRequestParams.language}`,
            };
            const request = yield axios_1.default.request(axiosConfig);
            return request.data;
        });
    }
    getStoreInformation(servicesApi_1, _a, businessUnitId_1, storeId_1) {
        return tslib_1.__awaiter(this, arguments, void 0, function* (servicesApi, { market, language, shopId }, businessUnitId, storeId, weeks = 1) {
            const axiosConfig = {
                method: 'GET',
                url: `${servicesApi}api/store/schedule`,
                params: {
                    market,
                    language,
                    businessUnitId,
                    fictiveWebShopId: shopId,
                    storeId,
                    weeks,
                },
            };
            const request = yield axios_1.default.request(axiosConfig);
            return request.data;
        });
    }
}
exports.default = new StoreAPI();
