"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutProvider = exports.useCheckoutState = exports.useCheckoutConfig = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const defaultState = {
    login: {
        email: '',
    },
};
const CheckoutConfigContext = (0, react_1.createContext)({});
const useCheckoutConfig = () => (0, react_1.useContext)(CheckoutConfigContext);
exports.useCheckoutConfig = useCheckoutConfig;
const CheckoutStateContext = (0, react_1.createContext)([]);
const useCheckoutState = () => (0, react_1.useContext)(CheckoutStateContext);
exports.useCheckoutState = useCheckoutState;
const reducer = (state, action) => {
    switch (action.type) {
        case 'update_login_data':
            return Object.assign(Object.assign({}, state), { login: Object.assign(Object.assign({}, state.login), action.payload) });
        default:
            return state;
    }
};
const CheckoutProvider = ({ children, config, }) => {
    return ((0, jsx_runtime_1.jsx)(CheckoutConfigContext.Provider, { value: config, children: (0, jsx_runtime_1.jsx)(CheckoutStateContext.Provider, { value: (0, react_1.useReducer)(reducer, Object.assign({}, defaultState)), children: children }) }));
};
exports.CheckoutProvider = CheckoutProvider;
